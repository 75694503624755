import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, push, set, onValue, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Container, Form, Button, Dropdown, Modal } from 'react-bootstrap';
import '../styles/message.css';
import person from '../assets/person.png';

const Message = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [blockStatus, setBlockStatus] = useState({
    currentUserBlocked: false,
    recipientBlocked: false,
  });
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportDetails, setReportDetails] = useState('');
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const messagesEndRef = useRef(null);

  // Fetch recipient, messages, and block status
  useEffect(() => {
    const db = getDatabase();

    const fetchRecipient = async () => {
      const recipientRef = ref(db, `users/${userId}`);
      const snapshot = await get(recipientRef);
      if (snapshot.exists()) {
        setRecipient(snapshot.val());
      }
    };

    const fetchMessages = () => {
      const messagesRef = ref(db, 'messages');
      onValue(messagesRef, (snapshot) => {
        if (snapshot.exists()) {
          const messageList = Object.keys(snapshot.val()).map((key) => ({
            id: key,
            ...snapshot.val()[key],
          })).filter(
            (message) =>
              (message.senderId === currentUser.uid && message.recipientId === userId) ||
              (message.senderId === userId && message.recipientId === currentUser.uid)
          ).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)); // Sort messages
          setMessages(messageList);
        }
      });
    };

    const checkBlockStatus = async () => {
      const currentUserBlockedRef = ref(db, `users/${currentUser.uid}/blockedUsers/${userId}`);
      const recipientBlockedRef = ref(db, `users/${userId}/blockedUsers/${currentUser.uid}`);

      const [currentUserBlockedSnapshot, recipientBlockedSnapshot] = await Promise.all([
        get(currentUserBlockedRef),
        get(recipientBlockedRef),
      ]);

      const currentUserBlocked = currentUserBlockedSnapshot.exists() && currentUserBlockedSnapshot.val().status;
      const recipientBlocked = recipientBlockedSnapshot.exists() && recipientBlockedSnapshot.val().status;

      setBlockStatus({
        currentUserBlocked,
        recipientBlocked,
      });
    };

    fetchRecipient();
    fetchMessages();
    checkBlockStatus();
  }, [userId, currentUser]);

  // Scroll to bottom of chat
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Open the direct link on component mount
  useEffect(() => {
    const adUrl = "https://www.highratecpm.com/cxp2pnf41g?key=47fe030ee77ae58ecc92e6ee6eff405f";
    window.open(adUrl, '_blank');
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async () => {
    if (blockStatus.currentUserBlocked || blockStatus.recipientBlocked || !newMessage.trim()) {
      return;
    }

    const db = getDatabase();
    const messagesRef = ref(db, 'messages');
    const newMessageRef = push(messagesRef);
    await set(newMessageRef, {
      senderId: currentUser.uid,
      recipientId: userId,
      text: newMessage,
      timestamp: new Date().toISOString(),
    });
    setNewMessage('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleClearChat = async () => {
    const db = getDatabase();
    const messagesRef = ref(db, 'messages');
    const snapshot = await get(messagesRef);
    if (snapshot.exists()) {
      const updates = {};
      snapshot.forEach((childSnapshot) => {
        const message = childSnapshot.val();
        if (
          (message.senderId === currentUser.uid && message.recipientId === userId) ||
          (message.senderId === userId && message.recipientId === currentUser.uid)
        ) {
          updates[childSnapshot.key] = null;
        }
      });
      await update(ref(db, 'messages'), updates);
      setMessages(messages.filter((message) => !updates[message.id]));
      navigate('/');
    }
  };

  const handleBlockUser = async () => {
    const db = getDatabase();
    const updates = {};
    updates[`users/${currentUser.uid}/blockedUsers/${userId}`] = { status: true };
    await update(ref(db), updates);
    setBlockStatus((prevState) => ({
      ...prevState,
      currentUserBlocked: true,
    }));
  };

  const handleUnblockUser = async () => {
    const db = getDatabase();
    const updates = {};
    updates[`users/${currentUser.uid}/blockedUsers/${userId}`] = null;
    await update(ref(db), updates);
    setBlockStatus((prevState) => ({
      ...prevState,
      currentUserBlocked: false,
    }));
  };

  const handleReportUser = () => {
    setShowReportModal(true);
  };

  const sendReport = async () => {
    if (!reportDetails.trim()) {
      alert('Please provide a report.');
      return;
    }

    const db = getDatabase();
    const reportsRef = ref(db, 'reports');
    const newReportRef = push(reportsRef);
    await set(newReportRef, {
      reporterId: currentUser.uid,
      reportedUserId: userId,
      reportDetails,
      timestamp: new Date().toISOString(),
    });
    alert('Report sent successfully.');
    setShowReportModal(false);
  };

  const handleMenuSelect = (eventKey) => {
    switch (eventKey) {
      case 'clear-chat':
        handleClearChat();
        break;
      case 'block':
        handleBlockUser();
        break;
      case 'unblock':
        handleUnblockUser();
        break;
      case 'report':
        handleReportUser();
        break;
      default:
        break;
    }
  };

  const groupedMessages = messages.reduce((acc, message) => {
    const messageDate = new Date(message.timestamp).toLocaleDateString();
    if (!acc[messageDate]) {
      acc[messageDate] = [];
    }
    acc[messageDate].push(message);
    return acc;
  }, {});

  const sortedGroupedMessages = Object.keys(groupedMessages)
    .sort((a, b) => new Date(b) - new Date(a)) // Sort dates in descending order
    .reduce((acc, date) => {
      acc[date] = groupedMessages[date].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      return acc;
    }, {});

  const messageItems = Object.keys(sortedGroupedMessages).map((date) => (
    <React.Fragment key={date}>
      <div className="date-divider">{date}</div>
      {sortedGroupedMessages[date].map((message) => (
        <div
          key={message.id}
          className={`message-item ${message.senderId === currentUser.uid ? 'sent' : 'received'}`}
        >
          <div className="message-content">
            <p>{message.text}</p>
          </div>
          <span className="message-timestamp">
            {new Date(message.timestamp).toLocaleTimeString()}
          </span>
        </div>
      ))}
    </React.Fragment>
  ));

  return (
    <Container className="message-container">
      {recipient && (
        <div className="message-header">
          <img
            src={recipient.profileImage || person}
            alt={recipient.name}
            className="recipient-image"
          />
          <div className="recipient-info">
            <h5>{recipient.name}</h5>
            <p>{recipient.online ? 'Online' : 'Recently Online'}</p>
          </div>
          <Dropdown onSelect={handleMenuSelect}>
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              <i className="fas fa-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="clear-chat">Clear Chat</Dropdown.Item>
              {!blockStatus.currentUserBlocked ? (
                <Dropdown.Item eventKey="block">Block</Dropdown.Item>
              ) : (
                <Dropdown.Item eventKey="unblock">Unblock</Dropdown.Item>
              )}
              <Dropdown.Item eventKey="report">Report</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <div className="message-body">
        {messageItems}
        <div ref={messagesEndRef}></div>
      </div>

      <Form className="message-input" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
        <Form.Control
          as="textarea"
          rows={1}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
          disabled={blockStatus.currentUserBlocked || blockStatus.recipientBlocked}
        />
        <Button
          variant="primary"
          onClick={handleSendMessage}
          disabled={blockStatus.currentUserBlocked || blockStatus.recipientBlocked}
        >
          Send
        </Button>
        <div className="text-center mt-3">
          <small>&copy; 2024 Naija Singles Connection. All rights reserved.</small>
          <div className="mt-2">
            <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a> | 
            <a href="/terms-conditions" className="text-blue-500 hover:underline">Terms and Conditions</a> | 
            <a href="/contact" className="text-blue-500 hover:underline">Contact Us</a>
          </div>
        </div>
      </Form>

      <Modal show={showReportModal} onHide={() => setShowReportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Report User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="reportDetails">
            <Form.Label>Report Details</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reportDetails}
              onChange={(e) => setReportDetails(e.target.value)}
              placeholder="Enter details of your report..."
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowReportModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={sendReport}>
            Send Report
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Message;
