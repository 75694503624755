import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import '../styles/login.css';
import logo from '../assets/favicon.png'; // Ensure the path to your logo is correct

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert('Password reset email sent!');
      setForgotPassword(false);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Logo" className="logo" />
      <div className="header-text">Naija Singles Connection</div>
      <div className="subtitle-text">Nigeria's biggest dating site for true lovers</div>
      <div className="login-box p-4 shadow rounded">
        <h2 className="text-center mb-4">Login</h2>
        {forgotPassword ? (
          <form onSubmit={handleResetPassword}>
            <div className="form-group mb-3">
              <label htmlFor="resetEmail" className="form-label">Enter your email address</label>
              <input
                type="email"
                className="form-control"
                id="resetEmail"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <button type="submit" className="btn btn-primary w-100">Send Reset Email</button>
            <button type="button" className="btn btn-link mt-2 w-100" onClick={() => setForgotPassword(false)}>Back to Login</button>
          </form>
        ) : (
          <>
            <form onSubmit={handleLogin}>
              <div className="form-group mb-3">
                <label htmlFor="email" className="form-label">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <button type="submit" className="btn btn-primary w-100">Login</button>
            </form>
            <div className="form-link mt-3 text-center">
              <span>Don't have an account? </span>
              <button className="btn btn-link p-0" onClick={() => navigate('/signup')}>Sign up here</button>
              <br />
              <button className="btn btn-link p-0 mt-2" onClick={() => setForgotPassword(true)}>Forgot Password?</button>
            </div>
          </>
        )}
      </div>
     
  <small>&copy; 2024 Naija Singles Connection. All rights reserved.</small>
 


    </div>
  );
};

export default Login;
