import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { Card, Button } from 'react-bootstrap'; // Import Bootstrap components
import { Link } from 'react-router-dom';
import person from '../assets/person.png';

const Like = ({ currentUser }) => {
  const [likedUsers, setLikedUsers] = useState([]);

  useEffect(() => {
    const fetchLikedUsers = async () => {
      try {
        const db = getDatabase();
        const userRef = ref(db, `users/${currentUser.uid}`);

        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const userData = snapshot.val();
          if (userData.likedBy) {
            // Get array of user IDs who are liked by current user
            const likedUserIds = Object.keys(userData.likedBy);

            // Fetch details of each liked user
            const promises = likedUserIds.map(async (userId) => {
              const userSnapshot = await get(ref(db, `users/${userId}`));
              return userSnapshot.val();
            });

            // Wait for all promises to resolve
            const likedUsersData = await Promise.all(promises);

            // Update state with liked users data
            setLikedUsers(likedUsersData);
          }
        }
      } catch (error) {
        console.error('Error fetching liked users: ', error);
      }
    };

    fetchLikedUsers();
  }, [currentUser]);

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">My Likes</h2> {/* Title added here */}
      <div className="row user-list">
        {likedUsers.map((user) => (
          <div key={user.id} className="col-md-4 mb-4">
            <Card className="user-card shadow-sm rounded">
              <Card.Img 
                variant="top" 
                src={user.profileImage || person} 
                className="user-card-img" 
              />
              <Card.Body className="user-details">
                <Card.Title>{user.name}</Card.Title>
                <Card.Text>
                  Gender: {user.gender}<br />
                  Age: {user.age}<br />
                  Location: {user.location}
                </Card.Text>
                <div className="d-flex justify-content-center">
                  <Button as={Link} to={`/message/${user.id}`} className="btn btn-primary message-btn">
                    Message
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      {/* Footer Section */}
      <footer className="mt-5 py-3 bg-light">
        <div className="container text-center">
          <p className="mb-0">
            <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a> |
            <a href="/terms-conditions" className="text-blue-500 hover:underline"> Terms and Conditions</a> |
            <a href="/contact" className="text-blue-500 hover:underline"> Contact Us</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Like;
