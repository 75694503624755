import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get } from 'firebase/database';
import { Form, Button } from 'react-bootstrap';

const Settings = ({ currentUser }) => {
  const [settings, setSettings] = useState({
    emailNotifications: false,
    pushNotifications: false,
    darkMode: false,
    // Add more settings fields as needed
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const db = getDatabase();
        const settingsRef = ref(db, `users/${currentUser.uid}/settings`);
        const snapshot = await get(settingsRef);

        if (snapshot.exists()) {
          setSettings(snapshot.val());
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching settings: ', error);
        setLoading(false);
      }
    };

    fetchSettings();
  }, [currentUser]);

  const handleSaveSettings = async () => {
    try {
      const db = getDatabase();
      const settingsRef = ref(db, `users/${currentUser.uid}/settings`);
      await set(settingsRef, settings);
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings: ', error);
      alert('Failed to save settings. Please try again.');
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: checked
    }));
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      <h2>Settings</h2>
      <Form>
        <Form.Check
          type="switch"
          id="emailNotifications"
          label="Email Notifications"
          name="emailNotifications"
          checked={settings.emailNotifications}
          onChange={handleChange}
        />
        <Form.Check
          type="switch"
          id="pushNotifications"
          label="Push Notifications"
          name="pushNotifications"
          checked={settings.pushNotifications}
          onChange={handleChange}
        />
        <Form.Check
          type="switch"
          id="darkMode"
          label="Dark Mode"
          name="darkMode"
          checked={settings.darkMode}
          onChange={handleChange}
        />
        {/* Add more settings fields as needed */}
        <Button variant="primary" onClick={handleSaveSettings}>
          Save Settings
        </Button>
      </Form>
    </div>
  );
};

export default Settings;
