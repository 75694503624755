import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { ListGroup, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/history.css';
import person from '../assets/person.png';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';

const History = () => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [recipientDetails, setRecipientDetails] = useState({}); // State to store recipient details
  const [userProfileImages, setUserProfileImages] = useState({}); // State to store profile images
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchMessageHistory = async () => {
      const db = getDatabase();
      const messagesRef = ref(db, 'messages');
      const usersRef = ref(db, 'users');

      onValue(messagesRef, (snapshot) => {
        if (snapshot.exists()) {
          const messages = snapshot.val();
          const userMessages = Object.keys(messages).map((key) => ({
            id: key,
            ...messages[key],
          })).filter(
            (message) =>
              message.senderId === currentUser.uid || message.recipientId === currentUser.uid
          );

          const userHistory = {};

          userMessages.forEach((message) => {
            const otherUserId =
              message.senderId === currentUser.uid ? message.recipientId : message.senderId;
            const otherUserName =
              message.senderId === currentUser.uid ? message.recipientName : message.senderName;
            if (!userHistory[otherUserId] || userHistory[otherUserId].timestamp < message.timestamp) {
              userHistory[otherUserId] = {
                id: otherUserId,
                name: otherUserName,
                lastMessage: message.text,
                timestamp: message.timestamp,
                isCurrentUser: message.senderId === currentUser.uid,
              };
            }
          });

          setMessageHistory(Object.values(userHistory));
        }
      });
    };

    fetchMessageHistory();
  }, [currentUser]);

  useEffect(() => {
    const fetchRecipientDetails = async () => {
      const db = getDatabase();
      const usersRef = ref(db, 'users');

      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const recipientIds = messageHistory.map(user => user.id);
          const recipientDetails = {};

          recipientIds.forEach((userId) => {
            const user = users[userId];
            if (user) {
              recipientDetails[userId] = {
                profileImageUrl: user.profileImageUrl || person,
                name: user.name || 'Unknown',
              };
            }
          });

          setRecipientDetails(recipientDetails);
        }
      });
    };

    fetchRecipientDetails();
  }, [messageHistory]);

  useEffect(() => {
    const fetchProfileImages = async () => {
      const db = getDatabase();
      const usersRef = ref(db, 'users');

      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const promises = Object.keys(users).map(async (userId) => {
            const user = users[userId];
            if (userId !== currentUser.uid && user.profileImageUrl) { // Only fetch profile image for other users
              try {
                const storage = getStorage();
                const imageRef = storageRef(storage, `users/${userId}/profileImage.jpg`);
                const imageUrl = await getDownloadURL(imageRef);
                return { [userId]: imageUrl };
              } catch (error) {
                console.error('Error fetching profile image:', error);
                return { [userId]: person }; // Fallback to default image
              }
            } else {
              return { [userId]: person }; // Fallback to default image
            }
          });

          Promise.all(promises).then((results) => {
            const profileImages = results.reduce((acc, result) => ({ ...acc, ...result }), {});
            setUserProfileImages(profileImages);
          });
        }
      });
    };

    fetchProfileImages();
  }, [currentUser]);

  return (
    <div className="container history-container mt-5">
      <h2 className="mt-3">Message History</h2>
      <ListGroup>
        {messageHistory.map((user) => (
          <ListGroup.Item key={user.id} className="d-flex justify-content-between align-items-center">
            <Link to={`/message/${user.id}`} className="history-link">
              <div className="d-flex align-items-center">
                <Image src={recipientDetails[user.id]?.profileImageUrl || person} roundedCircle width="100" height="100" className="mr-3" />
                <div>
                  <div className="font-weight-bold">{recipientDetails[user.id]?.name || 'Unknown'}</div>
                  <div className={`text-${user.isCurrentUser ? 'primary' : 'secondary'}`}>
                    {user.lastMessage}
                  </div>
                </div>
              </div>
              <div className="text-muted">
                {new Date(user.timestamp).toLocaleString()}
              </div>
            </Link>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {/* Footer Section */}
      <footer className="mt-5 py-3 bg-light">
        <div className="container text-center">
          <p className="mb-0">
            <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a> |
            <a href="/terms-conditions" className="text-blue-500 hover:underline"> Terms and Conditions</a> |
            <a href="/contact" className="text-blue-500 hover:underline"> Contact Us</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default History;
