import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { Card, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsChat } from 'react-icons/bs'; // Import Bootstrap icons
import '../styles/home.css'; // Import custom CSS
import person from '../assets/person.png';

const Match = ({ currentUser }) => {
  const [matches, setMatches] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected user details
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const db = getDatabase();
        const matchesRef = ref(db, `users/${currentUser.uid}/matches`);
        const snapshot = await get(matchesRef);
        if (snapshot.exists()) {
          const matchedUserIds = Object.keys(snapshot.val());
          const userPromises = matchedUserIds.map(async (userId) => {
            const userRef = ref(db, `users/${userId}`);
            const userSnapshot = await get(userRef);
            if (userSnapshot.exists()) {
              return { id: userId, ...userSnapshot.val() };
            }
            return null;
          });
          const matchedUsers = await Promise.all(userPromises);
          setMatches(matchedUsers.filter(user => user !== null));
        }
      } catch (error) {
        console.error('Error fetching matches: ', error);
      }
    };
    fetchMatches();
  }, [currentUser]);

  // Function to handle profile image click and show modal
  const handleImageClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Your Matches</h2>
      <div className="row user-list">
        {matches.map((user) => (
          <div key={user.id} className="col-md-4 mb-4">
            <Card className="user-card shadow-sm rounded">
              <div className="user-card-img-container">
                <Card.Img
                  variant="top"
                  src={user.profileImage|| person}
                  className="user-card-img"
                  onClick={() => handleImageClick(user)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <Card.Body className="user-details">
                <Card.Text className="text-center">
                  Name: {user.name}<br />
                  Gender: {user.gender}<br />
                  Age: {user.age}<br />
                  Location: {user.location}
                </Card.Text>
                <div className="d-flex justify-content-center">
                  <Button as={Link} to={`/message/${user.id}`} className="btn btn-primary message-btn">
                    <BsChat /> Message
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      {/* Modal to display detailed user information */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <div className="modal-user-profile text-center">
              <img src={selectedUser.profileImage || person} className="profile-image mb-3 rounded-circle" />
              <div className="profile-details">
                <h3>{selectedUser.name}</h3>
                <p><strong>Gender:</strong> {selectedUser.gender}</p>
                <p><strong>Age:</strong> {selectedUser.age}</p>
                <p><strong>Relationship Status:</strong> {selectedUser.relationshipStatus}</p>
                <p><strong>Location:</strong> {selectedUser.stateOfResident}</p>
                <p><strong>Has Children:</strong> {selectedUser.hasChildren}</p>
                <p><strong>Want Children:</strong> {selectedUser.wantChildren}</p>
                <p><strong>Info:</strong> {selectedUser.info}</p>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Footer Section */}
      <footer className="mt-5 py-3 bg-light">
        <div className="container text-center">
          <p className="mb-0">
            <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a> |
            <a href="/terms-conditions" className="text-blue-500 hover:underline"> Terms and Conditions</a> |
            <a href="/contact" className="text-blue-500 hover:underline"> Contact Us</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Match;
