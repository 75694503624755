import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { Card, Modal, Button } from 'react-bootstrap';
import person from '../assets/person.png'; // Import placeholder image
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { BsHeartFill, BsChat } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { formatDistanceToNow, parse } from 'date-fns';
import '../styles/view.css';

const View = ({ currentUser }) => {
  const [views, setViews] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchViews = async () => {
      try {
        const db = getDatabase();
        const viewsRef = ref(db, `users/${currentUser.uid}/views`);
        const snapshot = await get(viewsRef);

        if (snapshot.exists()) {
          const viewsList = Object.keys(snapshot.val()).map((key) => ({
            id: key,
            ...snapshot.val()[key],
          }));

          // Sort views by timestamp (ascending order - oldest first)
          viewsList.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

          setViews(viewsList);
        } else {
          setViews([]);
        }
      } catch (error) {
        console.error('Error fetching views:', error);
      }
    };

    fetchViews();
  }, [currentUser]); // Fetch views whenever currentUser changes

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const db = getDatabase();
        const dbRef = ref(db, 'users');
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const userList = Object.keys(snapshot.val()).map((key) => ({
            id: key,
            ...snapshot.val()[key],
            liked: false // Initialize liked property to false
          }));

          // Exclude current user from the list
          const filteredUsers = userList.filter(user => user.id !== currentUser.uid);
          const finalUsers = await Promise.all(filteredUsers.map(async (user) => {
            if (user.additionalImageUrls) {
              const images = await Promise.all(user.additionalImageUrls.map(async (url) => {
                const storage = getStorage();
                const imageRef = storageRef(storage, url);
                const imageUrl = await getDownloadURL(imageRef);
                return imageUrl;
              }));
              return { ...user, additionalImages: images };
            } else {
              return user;
            }
          }));

          setUsers(finalUsers);
        }
      } catch (error) {
        console.error('Error fetching users: ', error);
      }
    };

    fetchUsers();
  }, [currentUser]);

  const handleImageClick = async (user) => {
    setSelectedUser(user);
    setShowModal(true);

    // Record the view in the database
    const db = getDatabase();
    const viewsRef = ref(db, `users/${user.id}/views/${currentUser.uid}`);

    const viewData = {
      timestamp: new Date().toLocaleString('en-GB'), // Use localized format (DD/MM/YYYY, HH:MM:SS)
      viewerid: currentUser.uid
    };

    try {
      await set(viewsRef, viewData);
      console.log('View recorded successfully.');
    } catch (error) {
      console.error('Error recording view: ', error);
    }
  };

  const handleLikeClick = async (userId) => {
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}`);

    const updatedUsers = users.map(user => {
      if (user.id === userId) {
        const liked = !user.liked;
        if (liked) {
          user.likedBy = { ...user.likedBy, [currentUser.uid]: true };
        } else {
          delete user.likedBy[currentUser.uid];
        }
        return { ...user, liked };
      }
      return user;
    });

    setUsers(updatedUsers);

    try {
      await set(ref(userRef, 'likedBy'), updatedUsers.find(user => user.id === userId).likedBy);
      console.log('Like updated successfully.');
    } catch (error) {
      console.error('Error updating like: ', error);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const calculateTimeDifference = (timestamp) => {
    try {
      const parsedDate = parse(timestamp, 'dd/MM/yyyy, HH:mm:ss', new Date()); // Parse using date-fns parse function
      if (isNaN(parsedDate)) throw new Error('Invalid date');
      return formatDistanceToNow(parsedDate, { addSuffix: true });
    } catch (error) {
      console.error('Invalid timestamp format:', error);
      return 'Invalid date';
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">User Views</h2>
      <div className="column">
        {views.map((view) => {
          const user = users.find(user => user.id === view.viewerid);

          if (!user) return null;

          return (
            <div key={view.id} className="col-md-4 mb-4">
              <Card className="user-card shadow-sm rounded">
                <Card.Img 
                  variant="top" 
                  src={user.profileImageUrl || person} // Use profileImage or fallback to person.png
                  className="user-card-img" 
                  onClick={() => handleImageClick(user)}
                />
                <Card.Body className="user-details">
                  <Card.Title>{user.name}</Card.Title>
                  <Card.Text>
                    Gender: {user.gender}<br />
                    Age: {user.age}<br />
                    Location: {user.location}
                  </Card.Text>
                  <div className="d-flex justify-content-center">
                    <Button as={Link} to={`/message/${user.id}`} className="btn btn-primary message-btn">
                      <BsChat /> Message
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <BsHeartFill 
                      className={`like-icon ${user.likedBy && user.likedBy[currentUser.uid] ? 'filled' : ''}`} 
                      onClick={() => handleLikeClick(user.id)} 
                    />
                  </div>
                </Card.Body>
                <div className="timestamp text-right">
                  <p>{calculateTimeDifference(view.timestamp)}</p>
                </div>
              </Card>
            </div>
          );
        })}
      </div>

      {/* Modal to display detailed user information */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <div className="modal-user-profile text-center">
              <img
                src={selectedUser.profileImageUrl || person}
                className="profile-image mb-3 rounded-circle"
                alt="Profile"
              />
              <div className="profile-details">
                <h3>{selectedUser.name}</h3>
                <p>
                  <strong>Gender:</strong> {selectedUser.gender}
                  <br />
                  <strong>Age:</strong> {selectedUser.age}
                  <br />
                  <strong>Location:</strong> {selectedUser.location}
                  <br />
                  <strong>Relationship Status:</strong> {selectedUser.relationshipStatus}
                  <br />
                  <strong>Has Children:</strong> {selectedUser.hasChildren}
                  <br />
                  <strong>Want Children:</strong> {selectedUser.wantChildren}
                  <br />
                  <strong>Info:</strong> {selectedUser.info}
                </p>
                <div className="additional-images">
                  <h5>Additional Images:</h5>
                  <div className="additional-images-container">
                    {selectedUser.additionalImages && selectedUser.additionalImages.length > 0 ? (
                      selectedUser.additionalImages.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          className="additional-image rounded"
                          alt={`Additional Image ${index + 1}`}
                        />
                      ))
                    ) : (
                      <p>No additional images available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Footer */}
      <div className="text-center mt-3">
        <small>&copy; 2024 Naija Singles Connection. All rights reserved.</small>
        <div className="mt-2">
          <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a> | 
          <a href="/terms-conditions" className="text-blue-500 hover:underline">Terms and Conditions</a> | 
          <a href="/contact" className="text-blue-500 hover:underline">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default View;
