import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, update, get } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { BsHeartFill, BsHeart, BsFilter } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { BsChat } from 'react-icons/bs';
import '../styles/home.css'; // Import the CSS file
import person from '../assets/person.png';

const Home = ({ currentUser }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [adUrl, setAdUrl] = useState(null);
  const [filters, setFilters] = useState({
    minAge: '',
    maxAge: '',
    gender: '',
    relationshipStatus: '',
    hasChildren: '',
    wantChildren: ''
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const db = getDatabase();
        const dbRef = ref(db, 'users');
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const userList = Object.keys(snapshot.val()).map(key => ({
            id: key,
            ...snapshot.val()[key],
            liked: false // Initialize liked property to false
          }));

          // Exclude current user from the list
          const filteredUsers = userList.filter(user => user.id !== currentUser.uid);

          const finalUsers = await Promise.all(filteredUsers.map(async (user) => {
            if (user.additionalImageUrls) {
              const images = await Promise.all(user.additionalImageUrls.map(async (url) => {
                const storage = getStorage();
                const imageRef = storageRef(storage, url);
                const imageUrl = await getDownloadURL(imageRef);
                return imageUrl;
              }));
              return { ...user, additionalImages: images };
            } else {
              return user;
            }
          }));

          setUsers(finalUsers);
        }
      } catch (error) {
        console.error('Error fetching users: ', error);
      }
    };

    fetchUsers();
  }, [currentUser, filters]);

  useEffect(() => {
    // Update the liked state for users based on data from the database
    const fetchLikes = async () => {
      try {
        const db = getDatabase();
        const dbRef = ref(db, 'users');
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const userList = Object.keys(snapshot.val()).map(key => ({
            id: key,
            ...snapshot.val()[key],
          }));

          const updatedUsers = userList.map(user => {
            const liked = user.likedBy && user.likedBy[currentUser.uid] === true;
            return { ...user, liked };
          });

          setUsers(updatedUsers.filter(user => user.id !== currentUser.uid)); // Ensure current user is excluded
        }
      } catch (error) {
        console.error('Error fetching likes: ', error);
      }
    };

    fetchLikes();
  }, [currentUser]);

  const applyFilters = userList => {
    return userList.filter(user => {
      return (
        (filters.minAge === '' || user.age >= parseInt(filters.minAge)) &&
        (filters.maxAge === '' || user.age <= parseInt(filters.maxAge)) &&
        (filters.gender === '' || user.gender === filters.gender) &&
        (filters.relationshipStatus === '' || user.relationshipStatus === filters.relationshipStatus) &&
        (filters.hasChildren === '' || user.hasChildren === filters.hasChildren) &&
        (filters.wantChildren === '' || user.wantChildren === filters.wantChildren)
      );
    });
  };

  const handleFilterChange = e => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleImageClick = async user => {
    setSelectedUser(user);
    setAdUrl("https://www.highratecpm.com/cxp2pnf41g?key=47fe030ee77ae58ecc92e6ee6eff405f");
    setShowModal(true);

    // Record the view in the database
    const db = getDatabase();
    const viewsRef = ref(db, `users/${user.id}/views/${currentUser.uid}`);

    const viewData = {
      timestamp: new Date().toLocaleString(),
      viewerid: currentUser.uid
    };

    try {
      await set(viewsRef, viewData);
      console.log('View recorded successfully.');
    } catch (error) {
      console.error('Error recording view: ', error);
    }
  };

  const handleLikeClick = async userId => {
    const db = getDatabase();
    const userRef = ref(db, `users/${userId}`);

    const updatedUsers = users.map(user => {
      if (user.id === userId) {
        const liked = !user.liked;
        if (liked) {
          user.likedBy = { ...user.likedBy, [currentUser.uid]: true };
        } else {
          delete user.likedBy[currentUser.uid];
        }
        return { ...user, liked };
      }
      return user;
    });

    setUsers(updatedUsers);

    try {
      await update(userRef, {
        likedBy: updatedUsers.find(user => user.id === userId).likedBy
      });
      console.log('Like updated successfully.');
    } catch (error) {
      console.error('Error updating like: ', error);
    }
  };

  const openAdUrl = () => {
    if (adUrl) {
      window.open(adUrl, '_blank');
    }
  };

  return (
    <div className="home-container">
      {/* Filter Icon */}
      <div className="filters">
        <BsFilter className="filter-icon" onClick={() => setShowFilterModal(true)} />
      </div>

      {/* Filters Modal */}
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="formMinAge">
                  <Form.Label>Min Age</Form.Label>
                  <Form.Control
                    type="number"
                    name="minAge"
                    value={filters.minAge}
                    onChange={handleFilterChange}
                    placeholder="Min Age"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formMaxAge">
                  <Form.Label>Max Age</Form.Label>
                  <Form.Control
                    type="number"
                    name="maxAge"
                    value={filters.maxAge}
                    onChange={handleFilterChange}
                    placeholder="Max Age"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formGender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={filters.gender}
                onChange={handleFilterChange}
              >
                <option value="">All</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Non-Binary">Non-Binary</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formRelationshipStatus">
              <Form.Label>Relationship Status</Form.Label>
              <Form.Control
                as="select"
                name="relationshipStatus"
                value={filters.relationshipStatus}
                onChange={handleFilterChange}
              >
                <option value="">Any</option>
                <option value="Single">Single</option>
                <option value="In a Relationship">In a Relationship</option>
                <option value="Married">Married</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formHasChildren">
              <Form.Label>Has Children</Form.Label>
              <Form.Control
                as="select"
                name="hasChildren"
                value={filters.hasChildren}
                onChange={handleFilterChange}
              >
                <option value="">Any</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formWantChildren">
              <Form.Label>Wants Children</Form.Label>
              <Form.Control
                as="select"
                name="wantChildren"
                value={filters.wantChildren}
                onChange={handleFilterChange}
              >
                <option value="">Any</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => {
            setShowFilterModal(false);
            const filteredUsers = applyFilters(users);
            setUsers(filteredUsers);
          }}>
            Apply Filters
          </Button>
        </Modal.Footer>
      </Modal>

      {/* User List */}
      <div className="user-list">
        {users.map(user => (
          <div key={user.id} className="user-card">
            <div className="like-icon-container">
              {user.liked ? (
                <BsHeartFill className="like-icon filled" onClick={() => handleLikeClick(user.id)} />
              ) : (
                <BsHeart className="like-icon" onClick={() => handleLikeClick(user.id)} />
              )}
            </div>
            <img
              src={user.profileImage || person}
              alt={`${user.firstName}'s profile`}
              className="user-card-img"
              onClick={() => handleImageClick(user)}
            />
            <div className="user-details">
              <h5> {user.name} {user.lastName}</h5>
              <p>Age: {user.age} years old</p>
              <p>Gender: {user.gender}</p>
              <p>Location: {user.stateOfResidence}</p>
            </div>
            <div className="message-icon">
              <Button as={Link} to={`/message/${user.id}`} className="btn btn-primary message-btn">
                <BsChat /> Message
              </Button>
            </div>
          </div>
        ))}
      </div>

      {/* User Profile Modal */}
      <Modal show={showModal} onHide={() => {
        setShowModal(false);
        openAdUrl(); // Call the function to open the ad URL when the modal is closed
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedUser ? `${selectedUser.name} ${selectedUser.name}` : 'User Profile'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <div className="modal-user-profile">
              <img
                src={selectedUser.profileImage || person}
                alt={`${selectedUser.firstName}'s profile`}
                className="profile-image"
              />
              <h5>{selectedUser.name} {selectedUser.name}</h5>
              <p>
                <strong>Age:</strong> {selectedUser.age}
                <br />
                <strong>Gender:</strong> {selectedUser.gender}
                <br />
                <strong>Location:</strong> {selectedUser.stateOfResidence}
                <br />
                <strong>State of Origin:</strong> {selectedUser.stateOfOrigin}
                <br />
                <strong>Religion:</strong> {selectedUser.religion}
                <br />
                <strong>Relationship Status:</strong> {selectedUser.relationshipStatus}
                <br />
                <strong>Have Children:</strong> {selectedUser.haveChildren}
                <br />
                <strong>Want Children:</strong> {selectedUser.wantChildren}
                <br />
                <strong>Info:</strong> {selectedUser.userInfo}
              </p>
              <div className="additional-images-container">
                {selectedUser.additionalImages && selectedUser.additionalImages.length > 0 ? (
                  selectedUser.additionalImages.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl}
                      className="additional-image"
                      alt={`Additional Image ${index + 1}`}
                    />
                  ))
                ) : (
                  <p>No additional images available</p>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowModal(false);
            openAdUrl(); // Call the function to open the ad URL when the modal is closed
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="text-center mt-3">
        <small>&copy; 2024 Naija Singles Connection. All rights reserved.</small>
        <div className="mt-2">
          <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a> | 
          <a href="/terms-conditions" className="text-blue-500 hover:underline">Terms and Conditions</a> | 
          <a href="/contact" className="text-blue-500 hover:underline">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default Home;
