import React, { useState } from 'react';
import { PaystackConsumer } from 'react-paystack';
import { Form, Container, Row, Col, Alert } from 'react-bootstrap';
import '../styles/SubscriptionPaystack.css';

const SubscriptionPaystack = ({ currentUser }) => {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const [email, setEmail] = useState(currentUser.email);
  const [amount, setAmount] = useState(0);
  const [plan, setPlan] = useState('');
  const [validated, setValidated] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  const handlePlanChange = (e) => {
    const selectedPlan = e.target.value;
    setPlan(selectedPlan);

    let amountInKobo = 0;
    switch (selectedPlan) {
      case '1_month':
        amountInKobo = 150000; // 1,500 Naira
        break;
      case '3_months':
        amountInKobo = 400000; // 4,000 Naira
        break;
      case '6_months':
        amountInKobo = 700000; // 7,000 Naira
        break;
      case '1_year':
        amountInKobo = 1200000; // 12,000 Naira
        break;
      default:
        amountInKobo = 0;
    }
    setAmount(amountInKobo);
  };

  const onSuccessPayment = (response) => {
    // Handle successful payment here
    alert('Payment successful!');
    // Make API call to backend to update subscription status
    handleSubscription(true);
  };

  const onClosePayment = () => {
    // Handle closed payment here
    alert('Payment closed.');
  };

  const componentProps = {
    email,
    amount,
    publicKey,
    text: 'Pay with Card',
    onSuccess: onSuccessPayment,
    onClose: onClosePayment,
    plan_code: 'PLN_8phrd6wmnbp990w', // Replace with your Paystack plan code for 1500 Naira
  };

  const handleSubscription = async (status) => {
    try {
      const response = await fetch('/api/subscribe/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: currentUser.uid,
          status,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update subscription status');
      }
      console.log('Subscription status updated successfully');
    } catch (error) {
      console.error('Error updating subscription:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setValidated(true);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="text-center">Subscribe to a Plan</h2>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPlan">
              <Form.Label>Select Plan</Form.Label>
              <Form.Select required value={plan} onChange={handlePlanChange}>
                <option value="">Select a plan</option>
                <option value="1_month">Monthly - 1,500 Naira</option>
                <option value="3_months">3 Months - 4,000 Naira</option>
                <option value="6_months">6 Months - 7,000 Naira</option>
                <option value="1_year">1 Year - 12,000 Naira</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Please select a plan.
              </Form.Control.Feedback>
            </Form.Group>

            <PaystackConsumer {...componentProps}>
              {({ initializePayment }) => (
                <div className="text-center">
                  <button
                    className="paystack-button"
                    type="button"
                    onClick={() => initializePayment()}
                  >
                    Pay with Card
                  </button>
                </div>
              )}
            </PaystackConsumer>

            {paymentError && (
              <Alert variant="danger" className="mt-3">
                {paymentError}
              </Alert>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionPaystack;
