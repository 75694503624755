import React, { useState, useEffect } from 'react';
import { ref, get, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { db, storage, auth } from '../firebase/config'; // Import Firebase config
import '../styles/Profile.css'; // Ensure this path is correct
import person from '../assets/person.png';

const Profile = () => {
  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    gender: '',
    age: '',
    religion: '',
    relationshipStatus: '',
    stateOfOrigin: '',
    stateOfResident: '',
    hasChildren: '',
    wantChildren: '',
    userInfo: '',
    profileImage: '', // URL of the profile image
    additionalImages: [] // Array of additional image URLs
  });
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadType, setUploadType] = useState(''); // 'profile' or 'additional'
  const [replaceIndex, setReplaceIndex] = useState(null); // Index of the image to replace

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) {
          console.error('No user is logged in');
          setLoading(false);
          return;
        }

        // Fetch user data from Firebase Realtime Database
        const userRef = ref(db, `users/${userId}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const data = snapshot.val();

          // Handle profile image URL if exists
          let profileImageUrl = data.profileImage || person;
          let additionalImagesUrls = data.additionalImages || [];

          if (profileImageUrl) {
            const imageRef = storageRef(storage, profileImageUrl);
            try {
              profileImageUrl = await getDownloadURL(imageRef);
            } catch (error) {
              console.error('Error fetching profile image URL:', error);
              profileImageUrl = ''; // Fallback if error occurs
            }
          }

          // Fetch additional images URLs
          const additionalImages = await Promise.all(additionalImagesUrls.map(async (url) => {
            const imageRef = storageRef(storage, url);
            try {
              return await getDownloadURL(imageRef);
            } catch (error) {
              console.error('Error fetching additional image URL:', error);
              return ''; // Fallback if error occurs
            }
          }));

          setUserData({
            name: data.name || '',
            phone: data.phone || '',
            gender: data.gender || '',
            age: data.age || '',
            religion: data.religion || '',
            relationshipStatus: data.relationshipStatus || '',
            stateOfResidence: data.stateOfResidence || '',
            stateOfOrigin: data.stateOfOrigin || '',
            hasChildren: data.hasChildren || '',
            wantChildren: data.wantChildren || '',
            userInfo: data.userInfo || '',
            profileImage: profileImageUrl,
            additionalImages: additionalImages
          });
        } else {
          console.error('User data does not exist.');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [auth, db, storage]);

  const handleImageChange = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const userId = auth.currentUser?.uid;
      const fileName = `${userId}_${Date.now()}_${file.name}`;
      const imageRef = storageRef(storage, `images/${fileName}`);

      try {
        // Upload image
        await uploadBytes(imageRef, file);

        // Get the download URL
        const imageUrl = await getDownloadURL(imageRef);

        const updatedData = { ...userData };

        if (uploadType === 'profile') {
          updatedData.profileImage = imageUrl;
        } else if (uploadType === 'additional') {
          if (replaceIndex !== null) {
            // Replace the image at replaceIndex
            updatedData.additionalImages[replaceIndex] = imageUrl;
          } else if (userData.additionalImages.length < 3) {
            // Add new image if less than 3 images
            updatedData.additionalImages = [...userData.additionalImages, imageUrl];
          } else {
            alert('You can only have up to 3 additional images.');
            return;
          }
        }

        // Update Firebase Database
        const userRef = ref(db, `users/${userId}`);
        await update(userRef, {
          profileImage: updatedData.profileImage,
          additionalImages: updatedData.additionalImages
        });

        // Update state
        setUserData(updatedData);
        setSelectedImage(null);
        setUploadType('');
        setReplaceIndex(null); // Reset replaceIndex
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleProfileImageClick = () => {
    setUploadType('profile');
    document.getElementById('imageUploadInput').click();
  };

  const handleAdditionalImageClick = (index) => {
    setUploadType('additional');
    setReplaceIndex(index);
    document.getElementById('imageUploadInput').click();
  };

 

  return (
    <div className="container mt-5">
      <h2>Profile</h2>
      <div className="card">
        <div className="card-body">
          <div className="text-center mb-4">
            <img
              src={userData.profileImage || person} // Fallback image path
              alt="Profile"
              className="img-fluid rounded-circle"
              style={{ maxWidth: '150px' }}
              onClick={handleProfileImageClick}
            />
          </div>
          <h5 className="card-title">User Information</h5>
          <ul className="list-group list-group-flush">
            <li className="list-group-item"><strong>Name:</strong> {userData.name}</li>
            <li className="list-group-item"><strong>Phone:</strong> {userData.phone}</li>
            <li className="list-group-item"><strong>Gender:</strong> {userData.gender}</li>
            <li className="list-group-item"><strong>Age:</strong> {userData.age}</li>
            <li className="list-group-item"><strong>Relationship Status:</strong> {userData.relationshipStatus}</li>
            <li className="list-group-item"><strong>Location:</strong> {userData.stateOfResidence}</li>
            <li className="list-group-item"><strong>State:</strong> {userData.stateOfOrigin}</li>
            <li className="list-group-item"><strong>Religion:</strong> {userData.religion}</li>
            <li className="list-group-item"><strong>Do you have children?:</strong> {userData.hasChildren}</li>
            <li className="list-group-item"><strong>Do you want children?:</strong> {userData.wantChildren}</li>
            <li className="list-group-item"><strong>Your Info :</strong> {userData.userInfo}</li>
          </ul>

          {/* Display additional images if available */}
          <div className="additional-images mt-4">
            {userData.additionalImages.length > 0 ? (
              userData.additionalImages.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl}
                  alt={`Additional Image ${index + 1}`}
                  className="img-fluid additional-image"
                  style={{ marginRight: '10px', maxWidth: '200px', maxHeight: '150px' }}
                  onClick={() => handleAdditionalImageClick(index)}
                />
              ))
            ) : (
              <p>No additional images available</p>
            )}
          </div>

          {/* Hidden file input for image upload */}
          <input
            type="file"
            id="imageUploadInput"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
