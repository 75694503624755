import React, { useState } from 'react';
import { getDatabase, ref, set } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../styles/signup1.css'; // Ensure this CSS file matches login.css for consistency
import logo from '../assets/favicon.png';

// List of Nigerian states for dropdowns
const nigerianStates = [
  'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno',
  'Cross River', 'Delta', 'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'FCT', 'Gombe',
  'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Kogi', 'Kwara', 'Lagos', 'Nasarawa',
  'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto',
  'Taraba', 'Yobe', 'Zamfara'
];

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [religion, setReligion] = useState('');
  const [relationshipStatus, setRelationshipStatus] = useState('');
  const [stateOfOrigin, setStateOfOrigin] = useState('');
  const [stateOfResidence, setStateOfResidence] = useState('');
  const [haveChildren, setHaveChildren] = useState('');
  const [wantChildren, setWantChildren] = useState('');
  const [password, setPassword] = useState('');
  const [from, setFrom] = useState('');
  const [userInfo, setUserInfo] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([null, null, null]);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [additionalImageUrls, setAdditionalImageUrls] = useState(['', '', '']);
  const [step, setStep] = useState(1);
  const [paymentError, setPaymentError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();
  const database = getDatabase();

  const handleImageUpload = (file, path) => {
    if (!file) return Promise.resolve(null);
    const storagePath = `${path}/${Date.now()}_${file.name}`;
    const storageReference = storageRef(storage, storagePath);
    return uploadBytes(storageReference, file)
      .then(() => getDownloadURL(storageReference))
      .catch(error => {
        console.error('Error uploading image:', error);
        return null;
      });
  };

  const handleUserRegistration = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        const userId = userCredential.user.uid;

        const profileImagePromise = profileImage ? handleImageUpload(profileImage, 'profile_images') : Promise.resolve(null);
        const additionalImagePromises = additionalImages.map((img, index) => img ? handleImageUpload(img, `additional_images/${index}`) : Promise.resolve(null));

        Promise.all([profileImagePromise, ...additionalImagePromises])
          .then(([profileImageUrl, ...additionalImageUrls]) => {
            const newUserRef = ref(database, `users/${userId}`);
            set(newUserRef, { 
              email, name, age, gender, religion, relationshipStatus, stateOfOrigin, stateOfResidence, haveChildren, wantChildren, from, userInfo,
              profileImage: profileImageUrl,
              additionalImages: additionalImageUrls,
              Payment: false, // Default payment status
              Verify: false  // Default verification status
            })
            .then(() => navigate('/home'))
            .catch(error => {
              console.error('Error storing user data:', error);
              setPaymentError('Failed to register user data. Please try again.');
            });
          })
          .catch(error => {
            console.error('Error handling image uploads:', error);
            setPaymentError('Failed to upload images. Please try again.');
          });
      })
      .catch(error => {
        console.error('Error creating user:', error);
        setPaymentError('Failed to register user. Please try again.');
      });
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (step === 1 && email) {
      setStep(2);
    } else if (step === 2 && name) {
      setStep(3);
    } else if (step === 3 && age) {
      setStep(4);
    } else if (step === 4 && gender) {
      setStep(5);
    } else if (step === 5 && religion) {
      setStep(6);
    } else if (step === 6 && relationshipStatus) {
      setStep(7);
    } else if (step === 7 && stateOfOrigin) {
      setStep(8);
    } else if (step === 8 && stateOfResidence) {
      setStep(9);
    } else if (step === 9 && haveChildren) {
      setStep(10);
    } else if (step === 10 && wantChildren) {
      setStep(11);
    } else if (step === 11 && password) {
      setStep(12);
    } else if (step === 12 && from) {
      setStep(13);
    } else if (step === 13 && userInfo) {
      setStep(14);
    } else if (step === 14 && profileImage) {
      setStep(15);
    } else if (step === 15 && additionalImages.every(img => img)) {
      handleUserRegistration(); // Call handleUserRegistration directly after uploading all images
    } else {
      alert('Please fill out all fields and upload all required images.');
    }
  };

  const handleBack = () => {
    if (step === 1) {
      navigate('/login'); // Navigate to login page
    } else if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleImageChange = (e, index) => {
    const files = Array.from(e.target.files);
    if (index === -1) {
      setProfileImage(files[0]);
      setProfileImageUrl(URL.createObjectURL(files[0]));
    } else {
      setAdditionalImages(prev => {
        const updatedImages = [...prev];
        updatedImages[index] = files[0];
        return updatedImages;
      });
      setAdditionalImageUrls(prev => {
        const updatedUrls = [...prev];
        updatedUrls[index] = URL.createObjectURL(files[0]);
        return updatedUrls;
      });
    }
  };

  return (
    <div className="main-container"> 
    <img src={logo} alt="Logo" className="logo" />
      <div className="header-text">Naija Singles Connection</div>
      <div className="subtitle-text">Nigeria's biggest dating site for true lovers</div>
    <div className="signup-container">
      
      <h1 className="text-center mb-4">Create an Account</h1>
      {step === 1 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Enter your Email</h2>
          <div className="form-group mb-3">
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 2 */}
      {step === 2 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Enter your Name</h2>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 3 */}
      {step === 3 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Select Your Age</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
            >
               <option value="">Select Age</option>
        {[...Array(43).keys()].map(i => (
          <option key={i + 18} value={i + 18}>{i + 18}</option>
        ))}
      </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 4 */}
      {step === 4 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Select Your Gender</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
             
            </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 5 */}
      {step === 5 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Select Your Religion</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={religion}
              onChange={(e) => setReligion(e.target.value)}
              required
            >
              <option value="">Select Religion</option>
              <option value="Christianity">Christianity</option>
              <option value="Islam">Islam</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 6 */}
      {step === 6 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Select Your Relationship Status</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={relationshipStatus}
              onChange={(e) => setRelationshipStatus(e.target.value)}
              required
            >
              <option value="">Select Status</option>
              <option value="Single">Single</option>
              <option value="In a Relationship">In a Relationship</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="widow">widow</option>
            </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 7 */}
      {step === 7 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Select Your State of Origin</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={stateOfOrigin}
              onChange={(e) => setStateOfOrigin(e.target.value)}
              required
            >
              <option value="">Select State</option>
              {nigerianStates.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 8 */}
      {step === 8 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Select Your State of Residence</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={stateOfResidence}
              onChange={(e) => setStateOfResidence(e.target.value)}
              required
            >
              <option value="">Select State</option>
              {nigerianStates.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 9 */}
      {step === 9 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Do You Have Children?</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={haveChildren}
              onChange={(e) => setHaveChildren(e.target.value)}
              required
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 10 */}
      {step === 10 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Do You Want Children?</h2>
          <div className="form-group mb-3">
            <select
              className="form-control"
              value={wantChildren}
              onChange={(e) => setWantChildren(e.target.value)}
              required
            >
              <option value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 11 */}
      {step === 11 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Create a Password</h2>
          <div className="form-group mb-3">
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 12 */}
      {step === 12 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Where Are You From?</h2>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              placeholder="Enter your location"
              required
            />
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 13 */}
      {step === 13 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Tell Us About Yourself</h2>
          <div className="form-group mb-3">
            <textarea
              className="form-control"
              value={userInfo}
              onChange={(e) => setUserInfo(e.target.value)}
              placeholder="Tell us more about yourself"
              required
            />
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 14 */}
      {step === 14 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Upload Profile Image</h2>
          <div className="form-group mb-3">
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => handleImageChange(e, -1)}
              required
            />
            {profileImage && <img src={profileImageUrl} alt="Profile Preview" className="preview-image" />}
          </div>
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </form>
      )}
      {/* Step 15 */}
      {step === 15 && (
        <form onSubmit={handleNext} className="form-step p-4 shadow rounded">
          <h2 className="text-center mb-4">Upload Additional Images</h2>
          {[0, 1, 2].map(index => (
            <div key={index} className="form-group mb-3">
              <label>Image {index + 1}</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) => handleImageChange(e, index)}
                required
              />
              {additionalImages[index] && <img src={additionalImageUrls[index]} alt={`Additional Preview ${index + 1}`} className="preview-image" />}
            </div>
          ))}
          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={handleBack}>Back</button>
            <button type="submit" className="btn btn-primary">Complete Registration</button>
          </div>
          {paymentError && <div className="alert alert-danger mt-3">{paymentError}</div>}
        </form>
      )}
      
      </div>
      <div className="text-center mt-3">
      <small>&copy; 2024 Naija Singles Connection. All rights reserved.</small>
      </div>
    </div>
  );
};

export default SignUp;
