// src/index.js

// Importing React and ReactDOM libraries
import React from 'react';
import { createRoot } from 'react-dom/client';

// Importing the main App component
import App from './App';

// Importing global styles including Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * This is the entry point of the React application.
 * It initializes and renders the main App component into the root HTML element.
 */

// Getting the root element from the HTML document
const rootElement = document.getElementById('root');

// Creating a root to manage the rendering of the App component
const root = createRoot(rootElement);

// Rendering the App component within React's StrictMode for highlighting potential issues
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

